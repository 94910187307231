<swe-pageheader [header]="languageService.getItem(953)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Contract>0">

      <button *ngIf="permissionService.permissions.Contract>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="goto(0)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
      <swe-list [data]="contracts" [loading]="isloading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let contract of contracts" (click)="goto(contract.Id)">
          <div class="col-12 col-md-6">{{contract.Name}}</div>
          <div class="col-12 col-md-3">{{contract.FrameworkName}}</div>
          <div class="col-12 col-md-3">{{contract.TypeName}}</div>
        </a>
      </swe-list>
    </swe-card>
  </div>
  <div class="col-12" *ngIf="frameworkcontracts">
    <swe-card header="{{languageService.getItem(1274)}}" [open]="false" [hasRefresh]="false" [hasCollapse]="true">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{languageService.getItem(715)}}</th>
              <th>{{languageService.getItem(716)}}</th>
              <th style="width:50px">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let frameworkcontract of frameworkcontracts">
              <td><swe-element [type]="'System.String'" [(model)]="frameworkcontract.Name"></swe-element></td>
              <td><swe-element [type]="'System.String'" [(model)]="frameworkcontract.Desc"></swe-element></td>
              <td><button class="btn btn-primary" (click)="saveframeworkcontract(frameworkcontract)"><i class="bi bi-floppy2-fill"></i></button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><swe-element [type]="'System.String'" [(model)]="newframeworkcontract.Name"></swe-element></td>
              <td><swe-element [type]="'System.String'" [(model)]="newframeworkcontract.Desc"></swe-element></td>
              <td><button class="btn btn-primary" (click)="saveframeworkcontract(newframeworkcontract)" [disabled]="newframeworkcontract.Name&&newframeworkcontract.Name.length==0"><i class="bi bi-floppy2-fill"></i></button></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </swe-card>
  </div>
</div>
