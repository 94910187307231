<swe-pageheader [header]="languageService.getItem(114)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="reload()">
      <button swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="open($event, 0)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
      <div [class]="'list-group-item'" sweFixedHeader>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="{{languageService.getItem(736)}}" [(ngModel)]="settingService.searchcriteriarules" (keydown)="filter()" />
          <button class="btn btn-swe input-group-append"><i class="bi bi-x-lg swe-click input-group-addon" (click)="resetfilter()"></i></button>
        </div>
      </div>
      <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-danger': rule.Access=='Delete','list-group-item-warning': rule.Access=='Write','list-group-item-success': rule.Access=='Read'}" *ngFor="let rule of rules" (click)="open($event, rule.Id, rule.Keyword)">
        <div class="col-5 col-md-2 d-flex flex-wrap">
          <div class="col-12" *ngFor="let belonging of rule.Belonging">
            {{belonging}}
          </div>
        </div>
        <div class="col-7 col-md-10 d-flex flex-wrap">
          <div class="col-12 col-md-3">{{rule.Desc}}</div>
          <div class="col-12 col-md-3">{{rule.Keyword}}</div>
          <div class="col-4 col-md-1">{{rule.Access}}</div>
          <div class="col-4 col-md-1"><i class="bi bi-swe-2x bi-diagram-3-fill" *ngIf="rule.Child>1"></i><i class="bi bi-swe-2x bi-universal-access" *ngIf="rule.Child==1"></i></div>
          <div class="col-4 col-md-1"><i class="bi bi-swe-2x bi-person-fill" *ngIf="rule.OnlyYou"></i></div>
          <div class="col-4 col-md-1"><i class="bi bi-swe-2x bi-unlock-fill" *ngIf="rule.InclUnauth"></i></div>
          <div class="col-12 col-md-2">{{rule.LastMod}}</div>
        </div>
      </a>
    </swe-card>
  </div>
</div>
