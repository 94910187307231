<swe-pageheader [header]="languageService.getItem(1390)" *ngIf="permissionService.permissions.GuideAccess > 0"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader></swe-fixedheader>
   <div class="row py-2" *ngIf="permissionService.permissions.GuideAccess > 0">
    <div class="col-12">
      <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()">
        <button *ngIf="permissionService.permissions.GuideAccess>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="goto(0)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>

        <swe-list [data]="guides" [loading]="isLoading">
          <div class="list-group">
            <div class="d-none d-md-flex list-group-item" *ngIf="guides.length > 0">
              <strong class="col-xl-3 col-md-6 px-2">{{languageService.getItem(130)}}</strong>
              <strong class="col-xl-3 col-md-3 px-2">{{languageService.getItem(1)}}</strong>
              <strong class="col-xl-3 col-md-3 px-2">{{languageService.getItem(82)}}</strong>
              <strong class="col-xl-3 col-md-3 px-2 d-none d-xl-block">{{languageService.getItem(1391)}}</strong>
            </div>
            <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let guide of guides" (click)="goto(guide.Id)">
              <div class="col-12 col-xl-3 col-md-6 px-2">
                <strong class="d-md-none">{{languageService.getItem(130)}}:</strong> {{guide.Name}}
              </div>
              <div class="col-12 col-xl-3 col-md-3 px-2">
                <strong class="d-md-none">{{languageService.getItem(1)}}:</strong> {{guide.KeywordNames}}
              </div>
              <div class="col-12 col-xl-3 col-md-3 px-2">
                <strong class="d-md-none">{{languageService.getItem(82)}}:</strong> {{guide.Roles}}
              </div>
              <div class="col-12 col-xl-3 col-md-3 px-2 d-none d-xl-block">{{guide.Culture}}</div>
            </a>
          </div>
          <button class="btn btn-primary" *ngIf="more" (click)="load(true)">{{languageService.getItem(57)}}</button>
        </swe-list>
      </swe-card>
    </div>
  </div>
