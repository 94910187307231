import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from "../../_services/permission.service";
import { PropertyService } from "../../_services/property.service";
import { DateTimeService } from "../../_services/datetime.service";
import { GeneralService } from "../../_services/general.service";

@Component({
  selector: 'swe-templatesettingdata',
  templateUrl: './templatesettingdata.component.html'
})
export class TemplateSettingDataComponent implements OnInit {
  @Input() setting: any;
  @Input() properties: any[] = [];
  @Input() index: number = 0;
  @Input() maxIndex: number = 0;
  @Output() settingChange = new EventEmitter<any>();
  @Output() indexChange = new EventEmitter<any>();
  private _iterations: any[] = [];
  private _plustimetypes: any[] = [];
  private _neutraltimetypes: any[] = [];
  private _minustimetypes: any[] = [];
  private _weekdays: any[] = [];
  private _newbookingprofile: any = {};
  private _originalBreaks: any[] = [];

  public get iterations(): any[] {
    return this._iterations;
  }
  public get timetypes(): any[] {
    return this.getTimeTypes([0, 1]);
  }
  public get weekdays(): any[] {
    return this._weekdays
  }
  constructor(public languageService: LanguageService, public permissionService: PermissionService, private propertyService: PropertyService, private dateTimeService: DateTimeService, public generalService: GeneralService) {
  }
  public ngOnInit() {
    this.init();
  }

  public manageMealbreak(e) {
    this.setting.MealBreak = e;
    this.manageEnd();
  }

  public manageEnd() {
    let includedBreak = 0;
    this.setting.Breaks.forEach(b => {
        includedBreak += b.Break;
    });
    const start = new Date(this.setting.Start);
    this.setting.End = new Date(start.setHours(start.getHours() + this.setting.Hours, start.getMinutes() + this.setting.Minutes + includedBreak));
  }

  public manageActivityEnd(activity) {
    const start = new Date(activity.Start);
    const end = new Date(activity.End);
    const normalizedStart = new Date(1970, 0, 1, start.getHours(), start.getMinutes());
    let endDay = 1;
    const startMinutes = (start.getHours()  * 60) + start.getMinutes();
    const endMinutes = (end.getHours() * 60) + end.getMinutes();
    if (endMinutes < startMinutes) {
      endDay = 2;
    }
    const normalizedEnd = new Date(1970, 0, endDay, end.getHours(), end.getMinutes());
    const diffInMs = normalizedStart.getTime() - normalizedEnd.getTime();
    activity.EndMinutes = Math.abs(Math.floor(diffInMs / 1000 / 60));
  }

  public page(index) {
    this.index += index;
    this.indexChange.emit(this.index);
  }
  public addActivity(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setting.Activities.forEach(a => {
      a.IsOpen = false;
    });
    this.setting.Activities.push({ IsOpen: true });

  }
  public getActivityName(activityId) {
    let activity = this.generalService.activitytypes.find(x => x.Id == activityId);

    if (activity) {
      return this.languageService.getItem(1018) + ' - ' + activity.Name;
    }
    return this.languageService.getItem(1018);
  }

  public getPropertyName(propertyId) {
    let property = this.propertyService.getProperty(propertyId);
    if (property) {
      return this.languageService.getItem(1020) + ' - ' + property.Name;
    }
    return '';
  }

  public deleteBookingProfile(bookingprofile) {
    if (bookingprofile.Id > 0) {
      //Exists on server
      bookingprofile.IsDeleted = !bookingprofile.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this.setting.Properties.indexOf(bookingprofile);
      if (index > -1) {
        this.setting.Properties.splice(index, 1);
      }
    }
  }

  deleteActivity(activity) {
    if (activity.Id > 0) {
      //Exists on server
      activity.IsDeleted = !activity.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this.setting.Activities.indexOf(activity);
      if (index > -1) {
        this.setting.Activities.splice(index, 1);
      }
    }
  }

  public manageReload(filteritem) {
    if (filteritem.ReloadItems) {
      let property = this.propertyService.getProperty(filteritem.Id);
      if (property) {
        filteritem.Items = property.Items;
      }
      filteritem.ReloadItems = false;
    }
    else {
      return filteritem.Items;
    }
  }

  public addBookingProfile(e) {
    if (e) {
      e.stopPropagation();
    } this.setting.Properties.forEach(p => {
      p.IsOpen = false;
    });
    this.setting.Properties.push(this._newbookingprofile);
    this.propertyChosen(this._newbookingprofile.PropertyId);

    this.createNewBookingProfile();
  }

  public propertyChosen(e) {

    let property = this.propertyService.getProperty(e);
    if (property) {
      let bookingprofile = this.setting.Properties[this.setting.Properties.length - 1];
      if (bookingprofile) {
        bookingprofile.Type = property.Type;
        bookingprofile.Items = property.Items;
        if (bookingprofile.Type == 'System.DateTime') {
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else if (bookingprofile.Type == 'System.Date') {
          let now = new Date();
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0));
        }
        else if (bookingprofile.Type == 'System.Time') {
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else {
          bookingprofile.Value = '';
        }
      }
    }
  }

  private createNewBookingProfile() {

    let propertyId = 0;
    if (this.properties.length > 0) {
      propertyId = this.properties[0].Id;
    }

    this._newbookingprofile = {
      PropertyId: propertyId,
      IsOpen: true
    };
  }

  private init() {
    this.createNewBookingProfile();
    this._weekdays = [
      { Id: 2, Name: this.languageService.getItem(209).substring(0, 2) },
      { Id: 4, Name: this.languageService.getItem(210).substring(0, 2) },
      { Id: 8, Name: this.languageService.getItem(211).substring(0, 2) },
      { Id: 16, Name: this.languageService.getItem(212).substring(0, 2) },
      { Id: 32, Name: this.languageService.getItem(213).substring(0, 2) },
      { Id: 64, Name: this.languageService.getItem(214).substring(0, 2) },
      { Id: 1, Name: this.languageService.getItem(215).substring(0, 2) }
    ];

    this._iterations = [
      { Id: 0, Name: this.languageService.getItem(1427) },
      { Id: 1, Name: this.languageService.getItem(363) },
      { Id: 2, Name: this.languageService.getItem(364) },
      { Id: 3, Name: this.languageService.getItem(365) },
      { Id: 4, Name: this.languageService.getItem(366) },
      { Id: 5, Name: this.languageService.getItem(367) },
      { Id: 6, Name: this.languageService.getItem(368) },
      { Id: 7, Name: this.languageService.getItem(369) },
      { Id: 8, Name: this.languageService.getItem(370) },
      { Id: 9, Name: this.languageService.getItem(371) },
      { Id: 10, Name: this.languageService.getItem(372) }
    ];

    //TimeTypes
    this.generalService.timetypes.forEach((timetype) => {

      if (timetype.Extra < 0) {
        this._minustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else if (timetype.Extra == 0) {
        this._neutraltimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else {
        this._plustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }

    });
  }
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Name: '-----' });
      }

      if (sign > 0) {
        this._plustimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else if (sign == 0) {
        this._neutraltimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else {
        this._minustimetypes.forEach((item) => {
          list.push(item);
        });
      }

    });

    return list;
  }
}
