<swe-card header="{{title}}" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweEmploymentPlan','SweHasUserBookingProfile', 'SweUserBookings']" icon="bi-list-ul">
  <span swe-hits *ngIf="sum>0&&!employmentplan">({{generalService.formatdecimal(sum)}}h)<i class="bi bi-briefcase-fill text-danger ps-3" *ngIf="isactivenow" [attr.title]="isactivetext"></i></span>
  <swe-dropdown swe-rightcommand *ngIf="userdata.BookingAccess>1||userdata.NewTimereportAccess>0||userdata.NewAbscentTimereportAccess>0" icon="bi-plus-lg" [showLabel]="true" [(isOpen)]="addMenu" class="bi-swe-pull-right" [buttonclass]="'btn-success'" [label]="languageService.getItem(100)">
    <ul class="dropdown-menu" [ngClass]="{'show': addMenu }">
      <li class="dropdown-item swe-click" *ngIf="userdata.BookingAccess>1" (click)="goto(0,$event)">
        <i class="bi bi-swe-fw bi-list-ul" [attr.title]="languageService.getItem(63)"></i>
        {{languageService.getItem(63)}}
      </li>
      <li class="dropdown-item swe-click my-1" *ngIf="userdata.NewTimereportAccess>0" (click)="create(1, $event)">
        <i class="bi bi-swe-fw  bi-clock-fill" [attr.title]="languageService.getItem(1041)"></i>
        {{languageService.getItem(1041)}}
      </li>
      <li class="dropdown-item swe-click my-1" *ngIf="userdata.NewTimereportAccess>0" (click)="create(3, $event)">
        <i class="bi bi-swe-fw bi-swe-bookingtype-abscent" [attr.title]="languageService.getItem(1042)"></i>
        {{languageService.getItem(1042)}}
      </li>
    </ul>
  </swe-dropdown>
  <div class="mb-2">
    <div class="btn-group me-2" *ngIf="listutility.toggleaccess">
      <div class="dropdown">
        <!--CheckAll-->
        <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" [attr.disabled]="bookings.length==0 ? true: null">
          <span><i class="bi bi-check-lg"></i></span>
        </button>
        <!--Functionlist-->
        <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="bookingmenu=!bookingmenu" [attr.disabled]="listutility.ischecked ? null : true">
          <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
        </button>
        <ul class="dropdown-menu" [ngClass]="{'show': bookingmenu && listutility.ischecked}">
          <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiTimereport>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(669)}}</a></li>
          <li class="dropdown-divider" *ngIf="permissionService.permissions.Abscent>1"></li>
          <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>1"><a (click)="saveBooking(3);bookingmenu=false;"><i class="bi bi-swe-fw bi-person-fill"></i>&nbsp;{{languageService.getItem(283)}}</a></li>
        </ul>
      </div>
    </div>
    <swe-datepager [type]="'booking'" (dateChange)="search(false);listutility.checkall(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
    <div class="btn-group ms-1 align-top" *ngIf="more">
      <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
    </div>
    <swe-filteritems></swe-filteritems>
  </div>
  <ng-container *ngIf="permissionService.permissions.UserBookingProfile&&settingService.isDevice(['md','lg','xl', 'xxl'])">
    <!--Table-->
    <swe-table [data]="bookings" [loading]="loading">
      <table class="table" *ngFor="let groupby of groupedby | keyvalue">
        <thead>
          <tr class="bg-light" *ngIf="groupby.value.header.length>0">
            <th style="border-top:0; border-bottom:0;" [attr.colspan]="(groupby.value.items[0].Profile.length + 2)">
              <span>{{groupby.value.header}}</span>
              <span *ngIf="groupby.key==2&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumStandby)}} h)</span>
              <span *ngIf="groupby.key==3&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumAbscent)}} h)</span>
              <span *ngIf="groupby.key==4&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumDecline)}} h)</span>
            </th>
          </tr>
          <tr>
            <td style="border-top:0; border-bottom:0;"></td>
            <ng-container *ngIf="groupby.value">
              <th style="border-top:0; border-bottom:0;" class="swe-click" *ngFor="let header of groupby.value.items[0].Profile" (click)="sort(header.Property)">
                {{propertyService.getProperty(header.Property).Name}}
                <i class="bi bi-caret-up-fill" *ngIf="sortId==header.Property"></i>
                <i class="bi bi-caret-down-fill" *ngIf="(sortId*-1)==header.Property"></i>
              </th>
            </ng-container>
            <td style="border-top:0; border-bottom:0;"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let booking of groupby.value.items">
            <tr class="swe-click" [ngClass]="{'list-group-item-success': booking.Diff&&booking.Status>40&&!booking.checked, 'list-group-item-danger': booking.Diff&&booking.Status<50&&!booking.checked, 'list-group-item-warning swe-scroll-to': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}">
              <td (click)="booking.MarkAccess>0&&listutility.toggle(booking,$event)">
                <i class="bi bi-swe bi-check-circle-fill" *ngIf="booking.checked"></i>
                <i class="bi bi-swe-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
                <i class="bi bi-swe-border bi-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
                <i class="bi bi-swe-border bi-exclamation-circle text-warning" *ngIf="!booking.checked&&booking.ExternalCategoryName&&booking.ExternalCategoryName.length>0" [attr.title]="languageService.getItem(1248)+' : '+booking.ExternalCategoryName"></i>
                <i class="bi bi-swe-border bi-link-45deg" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
                <i class="bi bi-swe-border bi-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
                <i class="bi bi-swe-border bi-shield-shaded" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
                <i class="bi bi-swe-border bi-chat" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
                <i class="bi bi-swe-border bi-eye-fill" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
                <i class="bi bi-swe-border bi-swe-bookingtype-replaceable" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
                <i class="bi bi-swe-border bi-swe-bookingtype-standby" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
                <i class="bi bi-swe-border bi-swe-bookingtype-abscent" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
                <i class="bi bi-swe-border bi-swe-bookingtype-decline" *ngIf="!booking.checked&&booking.HasDecline&&!employmentplan" [attr.title]="languageService.getItem(1106)"></i>
              </td>
              <td class="swe-click" *ngFor="let profile of booking.Profile" (click)="goto(booking.Id,$event)" [ngSwitch]="profile.Type">
                <span *ngSwitchDefault>{{profile.FormatValue}}</span>
                <span *ngSwitchCase="'System.Boolean'"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-square': profile.FormatValue, 'bi-square': !profile.FormatValue}"></i></span>
              </td>
              <td>
                <i class="bi bi-swe-fw bi-lock-fill bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 60 && !booking.hover3}" *ngIf="booking.CompletedAccess > 0&&!booking.checked" (click)="change(booking, 60, $event)" (mouseover)="booking.hover3=true" (mouseout)="booking.hover3=false" [attr.title]="languageService.getItem(335)"></i>
                <i class="bi bi-swe-fw bi-check-lg bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 50 && !booking.hover2}" *ngIf="booking.ConfirmedAccess > 0&&!booking.checked" (click)="change(booking, 50, $event)" (mouseover)="booking.hover2=true" (mouseout)="booking.hover2=false" [attr.title]="languageService.getItem(334)"></i>
                <i class="bi bi-swe-fw bi-clock-fill bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 40 && !booking.hover1}" *ngIf="booking.TimereportAccess > 0&&!booking.checked" (click)="change(booking, 40, $event)" (mouseover)="booking.hover1=true" (mouseout)="booking.hover1=false" [attr.title]="languageService.getItem(333)"></i>
                <i class="bi bi-swe-fw bi-pencil-square bi-swe bi-swe-xs bi-swe-pull-right mb-1" *ngIf="showedit(booking)&&!booking.checked" (click)="timereport(booking.Timereport, $event)" [attr.title]="languageService.getItem(862)"></i>
                <swe-confirm [isbutton]="false" [body]="manageDeleteText(booking)" (acceptChange)="deletetimereport(booking.Timereport)" *ngIf="booking.DeleteTimereportAccess>2&&!booking.checked">
                  <i class="bi bi-swe-fw bi-trash-fill bi-swe bi-swe-pull-right mb-1" [attr.title]="languageService.getItem(17)"></i>
                </swe-confirm>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </swe-table>
  </ng-container>
  <ng-container *ngIf="!permissionService.permissions.UserBookingProfile||settingService.isDevice(['xs','sm'])">
    <!--List-->
    <swe-list [data]="bookings" [loading]="loading">
      <div *ngFor="let groupby of groupedby | keyvalue">
        <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light" *ngIf="groupby.value.header.length>0">
          <h2>
            <span>{{groupby.value.header}}</span>
            <span *ngIf="groupby.key==2&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumStandby)}} h)</span>
            <span *ngIf="groupby.key==3&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumAbscent)}} h)</span>
            <span *ngIf="groupby.key==4&&!employmentplan">&nbsp;({{generalService.formatdecimal(sumDecline)}} h)</span>
          </h2>
        </a>
        <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-success': booking.Diff&&booking.Status>40&&!booking.checked, 'list-group-item-danger': booking.Diff&&booking.Status<50&&!booking.checked, 'list-group-item-warning swe-scroll-to': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}" *ngFor="let booking of groupby.value.items" (click)="goto(booking.Id,$event)">
          <div class="col-2 col-md-1" (click)="booking.MarkAccess>0&&listutility.toggle(booking,$event)">
            <i class="bi bi-swe bi-check-circle-fill" *ngIf="booking.checked"></i>
            <i class="bi bi-swe-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
            <i class="bi bi-swe-border bi-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
            <i class="bi bi-swe-border bi-exclamation-circle text-warning" *ngIf="!booking.checked&&booking.ExternalCategoryName&&booking.ExternalCategoryName.length>0" [attr.title]="languageService.getItem(1248)+' : '+booking.ExternalCategoryName"></i>
            <i class="bi bi-swe-border bi-link-45deg" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
            <i class="bi bi-swe-border bi-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
            <i class="bi bi-swe-border bi-shield-shaded" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
            <i class="bi bi-swe-border bi-chat" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
            <i class="bi bi-swe-border bi-eye-fill" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
            <i class="bi bi-swe-border bi-swe-bookingtype-replaceable" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
            <i class="bi bi-swe-border bi-swe-bookingtype-standby" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
            <i class="bi bi-swe-border bi-swe-bookingtype-abscent" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
            <i class="bi bi-swe-border bi-swe-bookingtype-decline" *ngIf="!booking.checked&&booking.HasDecline&&!employmentplan" [attr.title]="languageService.getItem(1106)"></i>
          </div>
          <div class="col-10 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-3">{{booking.dateheader}}</div>
            <div class="col-12 col-md-3">{{booking.Header}} {{booking.TimeType}}</div>
            <div class="col-12 col-md-3" *ngIf="!permissionService.permissions.HideLevels">{{booking.Level}}</div>
            <div class="col-12 col-md-3">
              <i class="bi bi-swe-fw bi-lock-fill bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 60 && !booking.hover3}" *ngIf="booking.CompletedAccess > 0&&!booking.checked" (click)="change(booking, 60, $event)" (mouseover)="booking.hover3=true" (mouseout)="booking.hover3=false" [attr.title]="languageService.getItem(335)"></i>
              <i class="bi bi-swe-fw bi-check-lg bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 50 && !booking.hover2}" *ngIf="booking.ConfirmedAccess > 0&&!booking.checked" (click)="change(booking, 50, $event)" (mouseover)="booking.hover2=true" (mouseout)="booking.hover2=false" [attr.title]="languageService.getItem(334)"></i>
              <i class="bi bi-swe-fw bi-clock-fill bi-swe bi-swe-xs bi-swe-pull-right mb-1" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 40 && !booking.hover1}" *ngIf="booking.TimereportAccess > 0&&!booking.checked" (click)="change(booking, 40, $event)" (mouseover)="booking.hover1=true" (mouseout)="booking.hover1=false" [attr.title]="languageService.getItem(333)"></i>
              <i class="bi bi-swe-fw bi-pencil-square bi-swe bi-swe-xs bi-swe-pull-right mb-1" *ngIf="showedit(booking)&&!booking.checked" (click)="timereport(booking.Timereport, $event)" [attr.title]="languageService.getItem(862)"></i>
              <swe-confirm [isbutton]="false" [body]="manageDeleteText(booking)" (acceptChange)="deletetimereport(booking.Timereport)" *ngIf="booking.DeleteTimereportAccess>2&&!booking.checked">
                <i class="bi bi-swe-fw bi-trash-fill bi-swe bi-swe-pull-right mb-1" [attr.title]="languageService.getItem(17)"></i>
              </swe-confirm>
            </div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </div>
    </swe-list>
  </ng-container>
  <swe-generalarticles [id]="id" [open]="false" [isuser]="true" [salaryaccess]="userdata.ArticleSalaryAccess" [invoiceaccess]="userdata.ArticleInvoiceAccess" [idList]="allTimereportIdList" [cardcolor]="'swe'" *ngIf="type==0&&allTimereportIdList.length>0&&permissionService.permissions&&permissionService.permissions.Articles>0"></swe-generalarticles>
</swe-card>

<!--Profile when Book as Abscent-->
<swe-abscentdata [buttontext]="languageService.getItem(283)" [idList]="timereportIdList" [bookingid]="-1" [(visible)]="popup[0].Visible" [option]="popup[0].Option" (doneChange)="reloadAfterBook()"></swe-abscentdata>
