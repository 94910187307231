<swe-pageheader [header]="languageService.getItem(1234)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [header]="languageService.getItem(1280)" [open]="open" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Articles>0">
      <button *ngIf="permissionService.permissions.Articles>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="goto(0)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
      <swe-list [data]="articles" [loading]="isloading">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': article.Id==settingService.lastarticle}" *ngFor="let article of articles; index as i" (click)="goto(article.Id)">
          <div class="col-12 col-md-3">{{article.Name}}</div>
          <div class="col-12 col-md-1">{{article.PayType}}</div>
          <div class="col-12 col-md-2">{{article.QuantityPropertyName}}</div>
          <div class="col-12 col-md-2">{{article.PricePropertyName}}</div>
          <div class="col-12 col-md-1">{{article.StaffTypeName}}</div>
          <div class="col-12 col-md-1">{{article.SalaryTypeName}}</div>
          <div class="col-12 col-md-1">{{article.TypeName}}</div>
          <div class="col-12 col-md-1 text-end">
            <span class="me-3">{{article.Sort}}</span>
            <a href="javascript:void(0)" class="swe-no-link me-1" (click)="sort(i,true, $event)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-caret-up-fill': i>0}"></i></a>
            <a href="javascript:void(0)" class="swe-no-link me-1" (click)="sort(i,false, $event)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-caret-down-fill': i<articles.length-1}"></i></a>
          </div>
        </div>
      </swe-list>
    </swe-card>
  </div>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-generalarticles [header]="languageService.getItem(1281)" [open]="true" [isoverview]="true" [salaryaccess]="3" [invoiceaccess]="3" *ngIf="permissionService.permissions&&permissionService.permissions.Articles>0"></swe-generalarticles>
  </div>
</div>
