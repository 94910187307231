<swe-pageheader [header]="header"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [more]="more" [listutility]="listutility" (searchEvent)="handleSearchEvent($event)" [listoptions]="listoptions">
      <ng-template #listoptions>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click"><a (click)="action('multi', $event)"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
      </ul>
      </ng-template>
</swe-fixedheader>
<div class="row py-2" *ngIf="permissionService.hasObject(category)>0">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" (refreshChange)="search(false)" [rulelist]="['SweHasObject']">
      <button *ngIf="permissionService.hasObject(category)>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="open(0, $event)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
      <swe-list [data]="objects" [loading]="isloading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': object.Id==settingService.lastobject&&!object.checked,'list-group-item-info': object.checked}" *ngFor="let object of objects" (click)="open(object.Id, $event)">
          <div class="col-2 col-md-1" (click)="listutility.toggle(object, $event)">
            <span *ngIf="!object.checked">{{object.Id}}</span>
            <i class="bi bi-swe bi-check-circle-fill" *ngIf="object.checked"></i>
          </div>
          <div class="col-10 col-md-11 d-flex flex-wrap">
            <div class="col-xs-12 col-md-7">{{object.Header}}</div>
            <div class="col-xs-12 col-md-2">{{object.CreatedBy}}</div>
            <div class="col-xs-12 col-md-1"><span class="small" *ngIf="object.Created!=object.LastModified">{{object.Created}}</span></div>
            <div class="col-xs-12 col-md-2">{{object.LastModified}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
