<swe-pageheader [header]="languageService.getItem(1009)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Templates>0">
      <button *ngIf="permissionService.permissions.Templates>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded me-2 mb-2" (click)="goto(0)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
      <swe-list [data]="templates" [loading]="isloading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let template of templates" (click)="goto(template.Id)">
          <div class="col-12 col-md-4">{{template.Name}}</div>
          <div class="col-12 col-md-4">{{template.TypeName}}</div>
          <div class="col-12 col-md-4">{{template.LevelName}}</div>
        </a>
      </swe-list>
    </swe-card>
  </div>
</div>
